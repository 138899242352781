import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  return (
    <div className="container my-5">
      <div className="text-center">
        <img src="/profile.jpg" alt="Andrew Zapo" className="img-fluid rounded-circle mb-3" style={{ width: '150px' }} />
        <h1>Andrew Zapo</h1>
        <p>Toronto, Canada</p>
        <p>Email: <a href="mailto:zapoandrew@gmail.com">zapoandrew@gmail.com</a></p>
        <p>
          <a href="https://twitter.com/zapoandrew" target="_blank" rel="noopener noreferrer" className="btn btn-outline-primary mx-2">Twitter</a>
          <a href="https://linkedin.com/in/azapo" target="_blank" rel="noopener noreferrer" className="btn btn-outline-primary mx-2">LinkedIn</a>
        </p>
      </div>

      <section className="my-5">
        <h2>About Me</h2>
        <p>
          I’m a Web3 entrepreneur with expertise in marketing, growth, and product development. After starting in traditional marketing for brands like KFC, TD Bank, and L’Oréal, I transitioned into crypto in 2016. I’ve since helped over 100 blockchain companies succeed. Currently, I’m working on <a href="https://getsatoshi.org" target="_blank" rel="noopener noreferrer">tokenizing fractional Bitcoin</a> as a co-founder while also advising web3 companies on product and marketing.
        </p>
      </section>

      <section className="my-5">
        <h2>Highlights</h2>
        <ul>
          <li>Built a crypto-native press release service that generated $1M+ in its first year and was acquired by <a href="https://cointraffic.io" target="_blank" rel="noopener noreferrer">Cointraffic</a>.</li>
          <li>Served as CEO of <a href="https://blockgeeks.com" target="_blank" rel="noopener noreferrer">BlockGeeks</a>, one of the largest blockchain education companies with over 200k users.</li>
          <li>As COO of <a href="https://propykeys.com" target="_blank" rel="noopener noreferrer">PropyKeys</a>, managed operations and marketing, minting 260k NFT real estate addresses globally, with Coinbase Wallet as a partner.</li>
          <li>As CMO, I raised $24M and grew the community to 100k members at <a href="https://www.pantherprotocol.io" target="_blank" rel="noopener noreferrer">Panther Protocol</a>, and launched <a href="https://neonevm.org" target="_blank" rel="noopener noreferrer">NeonEVM</a> on Solana with 700k+ token sale subscribers on CoinList.</li>
          <li>Co-founded and launched the <a href="https://getsatoshi.org" target="_blank" rel="noopener noreferrer">Satoshi (SATO) token</a>, a Bitcoin-backed stablecoin.</li>
        </ul>
      </section>

      <section className="my-5">
        <h2>Concepts</h2>
        <ul>
          <li><strong>Connection:</strong> I value connecting with warm, driven people and aim to build lasting, loyal relationships. Good people create a shared space for exploration and growth.</li>
          <li><strong>Minimalism:</strong> I focus on the most important <a href="https://www.amazon.ca/ONE-Thing-Surprisingly-Extraordinary-Results/dp/1885167776/ref=asc_df_1885167776/?tag=googleshopc0c-20&linkCode=df0&hvadid=706745562862&hvpos=&hvnetw=g&hvrand=5860286949193394119&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9000833&hvtargid=pla-395617944179&psc=1&mcid=cfc4dc8537063965b0b03d9b13b4fd68&gad_source=1" target="_blank" rel="noopener noreferrer">one thing</a> and eliminate distractions in both life and work. Simple structures are often more reliable and less prone to breaking.</li>
          <li><strong>Clarity:</strong> I prioritize clarity over aesthetics, ensuring things are clear before making them beautiful. Clarity builds trust.</li>
          <li><strong>Confluence:</strong> Important dots often seek to be connected; you just need to be focused enough to see them. Connected dots lead to meaningful ideas.</li>
          <li><strong>Art + Business:</strong> Good art satisfies the artist, while good business satisfies customers. A great product does both.</li>
        </ul>
      </section>

    </div>
  );
}

export default App;
